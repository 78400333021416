body {
  margin: 0;
}

.resource-label {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin-bottom: 8px;
  letter-spacing: 1px;
  font-size: 0.92rem;
  text-transform: uppercase;
}

.resource-label-icon {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  margin-right: 6px;
  font-size: 1.1rem;
}

.ListItem-inner {
  padding: 10px;
  border-left: 1px solid #c8cdd1;
  border-right: 1px solid #c8cdd1;
}

.MenuItem {
  color: #3b4a57;
  text-decoration: none;
  padding: 6px 8px;
  padding-left: 16px;
  display: block;
  border-left: 3px solid transparent;
}

.MenuItem:hover:not(.active) {
  text-decoration: underline;
}

.MenuItem.active,
.MenuItem:active {
  color: rgb(37, 120, 203);
  border-left: 3px solid rgb(37, 120, 203);
}

ul.ModuleList,
ul.unstyled-list,
ul.ExpandCollapseList {
  list-style: none;
  padding: 0;
  margin: 0;
}

ol.assessment-questions {
  padding: 0 40px;
}

.unstyled-list li {
  padding: 0;
  margin: 0;
}

h3 {
  margin: 0;
  font-size: 1.125rem;
  font-family: "LatoWeb", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.ExpandCollapseList {
  border: 1px solid #e9ebed;
  border-radius: 4px;
}

.Module {
  list-style: none;
  margin-bottom: 24px;
}

.ExpandCollapseList-item:first-of-type {
  border-top: none;
}

.ExpandCollapseList-item-inner {
  display: -ms-flexbox;
  display: flex;
}

.ExpandCollapseList .ExpandCollapseList .ExpandCollapseList-item:first-of-type {
  border-top: 1px solid #e9ebed;
}

.ExpandCollapseList-item {
  border-top: 1px solid #e9ebed;
  padding: 12px;
}

.ExpandCollapseList .ExpandCollapseList {
  border: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.ExpandCollapseList-item-pts {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-direction: column;
      flex-direction: column;
}

.ExpandCollapseList-item-workflow-state {
  margin-left: 8px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-direction: column;
      flex-direction: column;
}

.ExpandCollapseList-item-details {
  color: #4e575f;
  font-size: 0.82rem;
  margin-top: 4px;
}

.resource-icon {
  margin-right: 12px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-direction: column;
      flex-direction: column;
}

.RichContent img {
  max-width: 100%;
}

.assessment-questions .RichContent p {
  margin: 0 0 10px 0 !important;
}

.course-title {
  margin: 0;
  font-family: LatoWeb, Lato, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 1.8rem;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  -webkit-animation: fade-in 0.5s 1;
  animation: fade-in 0.5s 1;
}

.hidden {
  display: none;
}

.submission-format:not(:last-of-type)::after {
  content: ", ";
}

.preview-not-available-icon {
  width: 282px;
  margin: 80px 0;
}

.RichContent iframe {
  border: 0 none;
}

.EmbeddedPreview iframe {
  width: 100%;
  height: 800px;
  border: 0 none;
}

.EmbeddedPreview--video,
.EmbeddedPreview--audio {
  max-width: 100%;
}

.Assessment--IconCheckMark {
  min-width: 32px;
}

a:focus,
main:focus {
  outline: 2px solid #008ee2 !important;
}

a,
main {
  outline: 2px solid transparent !important;
}

table {
  width: 100%;
  border-radius: 5px;
  border-collapse: collapse;
}

table,
th,
td {
  border: 1px solid #b4b4b4;
}

th,
td {
  padding: 0.5rem 1rem;
}

pdf-iframe-rv .footer {
  display: none;
}

/*
  CSS Grid styles for the cartridge view
  and hamburger menu for mobile
*/

.CommonCartridge--view {
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 200px 1fr;
}

.CommonCartridge--view.single-resource {
  grid-template-columns: 1fr;
}

.CommonCartridge--view nav {
  padding-top: 20px;
}

.Resource--navButtons {
  display: grid;
  padding: 10px 0 30px 0;
  grid-template-columns: auto auto;
  grid-template-areas: "prev next";
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-line-pack: justify;
      align-content: space-between;
}

.previous-link {
  grid-area: prev;
}

.print-link {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
}

.next-link {
  grid-area: next;
}

#CommonCartridge--navToggle,
.CommonCartridge--view nav label,
#NavTrigger {
  display: none;
}

.CommonCartridge--view nav .open,
.CommonCartridge--view nav .close {
  position: absolute;
  top: 7px;
  left: 8px;
}

.CommonCartridge--content {
  overflow: auto;
}

.sm-btn-group-tmp {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
  margin-bottom: 20px;
}

:root {
  /** Resource type colors **/
  --color-course: #008ee2;
  --color-document: #008ee2;
  --color-module: #00ac18;
  --color-image: #00ac18;
  --color-audio: #66189d;
  --color-discussion: #66189d;
  --color-video: #fc5e13;
  --color-page: #fc5e13;
  --color-quiz: #ee0612;
  --color-assignment: #bf32a4;
  --color-external-link: #ad4aa0;
}

@media screen and (max-width: 550px) {
  .CommonCartridge--view nav {
    padding: 0;
    min-height: 50px;
  }

  .CommonCartridge--view {
    grid-template-columns: 1fr;
  }

  header ol {
    margin-left: 45px !important;
  }

  .Resource--navButtons {
    padding-top: 0;
  }

  .CommonCartridge--view nav .open {
    display: block;
  }

  .MenuItem {
    padding-left: 6px;
  }

  #NavTrigger:checked + .open {
    display: none;
  }

  .navLinks {
    padding: 0 10px;
    max-height: 0;
    overflow: hidden;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    opacity: 0;
  }

  #NavTrigger:checked + .open + .navLinks + .close {
    display: block;
  }

  #NavTrigger:checked + .open + .navLinks {
    padding: 50px 10px 10px 10px;
    max-height: 100vh;
    opacity: 1;
  }
}

@page {
  size: 8.5in 11in;
  margin: 0.5in;

  /* Running Page Headers and Footers: https://www.w3.org/TR/css-gcpm-3/#running-elements */
  /* @top-right {
    content: "Chapter 1: The Machine";
    content: element(pageFooter);
  } */
}

/* Running Page Headers and Footers: https://www.w3.org/TR/css-gcpm-3/#running-elements */
/* @page {
  @top-left { content: "Header in top-left with approx. twice as many words as right cell." }
  @top-right { content: "Right cell (top-right)" }
} */

/* Running Page Headers and Footers: https://www.w3.org/TR/css-gcpm-3/#running-elements */
/* #pageFooter {
  position: running(pageFooter);
  position: fixed;
} */

.syllabus-video-alt-text {
  display: none;
}

@media print {
  .CommonCartridge--view,
  .CommonCartridge--content,
  .CommonCartridge--content main,
  #ltiFrame,
  #upperFrame,
  #lowerFrame {
    width: 8in;
    overflow: hidden;
  }

  .CommonCartridge--view,
  .Resource--navButtons,
  .print-link, .resource-label, nav, form,
  .syllabus-iframe{
    display: none;
  }

  .CommonCartridge--view, .syllabus-video-alt-text {
    display: block;
  }

  .CommonCartridge--content {
    -ms-flex-positive: 1;
        flex-grow: 1;
  }

  ol, ul {
    page-break-inside: avoid;
  }

  body {
    position: relative;
    padding-bottom: 1cm;
    /* Add padding to the bottom of the body to create space for the copyright notice */
    margin-bottom: 1cm;
  }

  body::after {
    content: "\A9  2023 StrongMind, Inc. All Rights Reserved";
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    font-size: 12px;
    text-align: center;
    padding: 0.5cm 0;
    z-index: 999;
    /* Adjust padding as needed */
  }
}

.font-color.type-assignment {
  color: var(--color-assignment);
}
.font-color.type-audio {
  color: var(--color-audio);
}
.font-color.type-discussion_topic {
  color: var(--color-discussion);
}
.font-color.type-course {
  color: var(--color-course);
}
.font-color.type-document {
  color: var(--color-document);
}
.font-color.type-image {
  color: var(--color-image);
}
.font-color.type-module {
  color: var(--color-module);
}
.font-color.type-page {
  color: var(--color-page);
}
.font-color.type-quiz {
  color: var(--color-quiz);
}
.font-color.type-video {
  color: var(--color-video);
}
.font-color.type-external_link {
  color: var(--color-external-link);
}

.background-color.type-assignment {
  background-color: var(--color-assignment);
}
.background-color.type-audio {
  background-color: var(--color-audio);
}
.background-color.type-discussion_topic {
  background-color: var(--color-discussion);
}
.background-color.type-course {
  background-color: var(--color-course);
}
.background-color.type-document {
  background-color: var(--color-document);
}
.background-color.type-image {
  background-color: var(--color-image);
}
.background-color.type-module {
  background-color: var(--color-module);
}
.background-color.type-page {
  background-color: var(--color-page);
}
.background-color.type-quiz {
  background-color: var(--color-quiz);
}
.background-color.type-video {
  background-color: var(--color-video);
}
.background-color.type-external_link {
  background-color: var(--color-external-link);
}

